var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-home"},[_c('div',{staticClass:"container is-fluid"},[_vm._m(0),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-flex is-flex-wrap-wrap-reverse hast-text-left"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.range_date),callback:function ($$v) {_vm.range_date=$$v},expression:"range_date"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una opcion")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Tres meses a vencer")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Dos meses a vencer")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Vencida")])])],1)]}}],null,true)})],1)]),_c('div',{staticClass:"is-flex is-justify-content-flex-end pl-3"},[_c('b-button',{staticClass:"button btn-primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.getSearch)}}},[_vm._v("Buscar")])],1)])]}}])})],1)])]),_c('div',{staticClass:"container is-fluid pt-6"},[_c('b-table',{attrs:{"data":_vm.data,"loading":_vm.loading,"paginated":true,"per-page":10,"current-page":_vm.currentPage},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"is-flex is-justify-content-center"},[_c('Animation',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":'assets/animation/empty.json'}})],1)]},proxy:true}])},_vm._l((_vm.header),function(item){return _c('b-table-column',{key:item.field,attrs:{"field":item.field,"label":item.label,"width":item.width ? item.width : 100,"sortable":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [[(item.field == 'migratory_status')?_c('div',[_c('span',{class:props.row[item.field] == 'ACTIVO'
                  ? 'tag is-success'
                  : 'tag is-danger'},[_vm._v(" "+_vm._s(props.row[item.field]))])]):(item.label == 'Doc')?[_c('a',{attrs:{"href":_vm.baseUrl + props.row.file,"target":"blank"}},[_c('i',{staticClass:"mdi mdi-file-image-outline has-text-black"})])]:_c('div',[_vm._v(" "+_vm._s(props.row[item.field])+" ")])]]}}],null,true)})}),1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns pt-4"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v("Lista de vencimiento de cédulas Crm")])])])])}]

export { render, staticRenderFns }